.login-section{

  .white-icon {
    color: #fff !important;
    font-size: 35px;
  }

  .panel-danger {

    border-radius:0px;
    border-color: #d8d8d8;
    .panel-heading {
      color: #fff;
      border-radius:0;
      background: $default-red;
    }
    .panel-body {

      h2{
        font-weight: normal;
        text-align: left !important;
        margin-top:0px;
      }
      img{
        border-radius: 0px !important;
      }
    }

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  a{
    color:$default-red;
    font-weight: bold;
  }
  .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    border-top: 0px !important;
  }
}

.register-section{
  margin-top:30px;
  h2{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  margin-bottom: 100px;
}

.header-panel {
  .row {
    display: flex;
    align-items: center;
  }
}

.aep-red{
  background-color: $default-red;
}
.aep-red-text{
  color: $default-red;
}

.booking-cookie-block{
  margin-top: 60px;
}

#sticker {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100;
  right: 0;
}

.inframe-terms-and-conditions{
  display: block;
  height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  padding: 20px;

  h2 {
    margin-top: 0;
  }
}

.admin-area {
  .panel {
    .panel-body {
      .btn {
        margin: 5px;
      }

      .list-group {
        margin-bottom: 0;
      }
    }
  }
}
