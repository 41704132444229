.courseMaterials {
    &-nextLesson-view, &-bookPacks-view {
        &-images {
            margin: 2em 0;

            img {
                max-width: 100%;
            }
        }

        .item {
            background-color: $default-grey;
            padding: 0.5em 2em 1em;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
        }
    }

    &-bookPacks-view {
        h3 {
            text-transform: capitalize;
        }

        &-previous {
            margin: 2em 0 1em
        }

        hr {
            border-color: #CCC;
        }

        strong.text--red {
            color: $default-red;
        }

        .text--bold {
            font-weight: 700;
        }
    }

    &-contactInfo {
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: darken($default-grey, 8%);
        padding: 1em 2em 0.5em;
        margin: 1.5em -2em 1.5em;
    }
}