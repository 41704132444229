
// Register page
body#register-new {

  .content-header{
    h1{
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 20px;
      color: $default-red;
      margin-bottom:0px;
    }
    p{
      margin-top:10px;
      font-weight: bold;
      color:#000;
      a{
        font-weight: bold;
        color: $default-red;
      }
    }
  }
.no-margin-bottom{
  margin-bottom: initial !important;
}
  .content-form{



    h3{
      font-size:30px;
      color:#727272;
    }
    h4{
      margin-top:25px;
      margin-bottom:35px;
      font-size:25px;
      color:#000;
      font-weight: bold;
    }



    label{
      color: #000;
      font-weight:200;
    }
    input[name=primary_phone_home], input[name=primary_phone_mobile], input[name=primary_phone_other] {
      margin-bottom: 10px;
    }
    input[name=primary_address_1], input[name=primary_address_2], input[name=primary_address_3] {
      margin-bottom: 10px;
    }

    ///secondary_phone_home
    input[name=secondary_phone_home], input[name=secondary_phone_mobile], input[name=secondary_phone_other] {
      margin-bottom: 10px;
    }
  }



}
