.videos-show {
    &-main {
        .alert {
            margin: 1em 0 2em;

            svg {
                height: 1em;
                width: 1.35em;
                line-height: 0;

                g {
                    fill: $alert-info-text;
                }
            }
        }
    }
}
