
@media(min-width:1200px){
  .footer-menu {
    float: right;
  }
  .footer-info {
    float: left;
  }
  .header-menu{
    display:block!important;
  }
}
