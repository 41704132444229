/**/
@media screen and (min-width: 769px){
  .submit-area input.submit {
    width: auto;
  }
}
@media screen and (min-width: 769px) and (max-width:992px) {
  .course-title {
      padding-bottom: 5px;
  }
  .header-menu-container {
    //bottom: -50px;
  }
  .header-menu-list li a {
    padding: 10px 15px;
  }
  .header-separator{
    margin-bottom:30px;
  }
  .header-logo {
      margin-bottom: 25px;
  }

  .group-item{
    height:auto;
  }

  .header-menu{
    display:block!important;
  }

  .group-field {
      width: 110px;
  }

  .secondary-parent-group .form-group-phone {
    width: 100%;
  }

  .xs-hide{
    display:none!important;
  }

  .xs-show{
    display:block!important;
  }

  .summary-image img {
    margin-bottom: 30px;
}





}

