.admin-area {
    .help-block {
        font-size: 12px;
    }

    .form-group {
        margin-bottom: 1.5em;

        h4, p {
            color: #666;
        }

        &--file {
            display: flex;
            justify-content: space-between;
            margin-top: 1em;
        }

        &-uploadedImage {
            padding: 1em;
            background-color: $gray-lighter;
            margin-bottom: 1em;

            p {
                color: black
            }

            img {
                width: 100%;
            }

            a {
                margin-top: 1em;
            }
        }

        .dropdown-menu {
            > .selected > a {
                background-color: $alert-info-bg;

                &:hover {
                    background-color: darken($alert-info-bg, 4%);
                }
            }
        }
    }
}
