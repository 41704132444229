@use 'sass:math';

#bookings {
    .bookings-event {
        margin-top: 1em;
        color: black;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid $default-red;
        display: grid;
        grid-template-columns: percentage(math.div(3, 12)) 2fr;

        &-left {
            background-color: $default-red;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            img {
                max-width: 160px;
            }
        }

        &-right {
            padding: 1em 1.25em;
            display: grid;
            grid-template-columns: auto max-content;
        }

        h3 {
            font-size: 1em;
            margin-top: 0.5em;
            margin-bottom: 1.75em;
            padding-bottom: 1em;
            border-bottom: 1px dashed $default-red;
            font-weight: bold;
            grid-column: 1 / 3;
        }

        &-info {
            display: grid;
            grid-template-columns: percentage(math.div(5, 12)) 1fr;

            @media screen and (max-width: 767px) {
                grid-column: 1 / 3;
            }

            p:nth-child(2n - 1) {
                font-weight: bold;
            }
        }

        &-controls {
            margin-top: 1em;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @media screen and (max-width: 767px) {
                grid-column: 1 / 3;
            }

            a {
                padding-left: 1.5em;
                padding-right: 1.5em;
            }
        }
    }
}