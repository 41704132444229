@media screen and (min-width: 992px) and (max-width:1200px) {

  .header-menu-list li a{
      padding:0 5px;
    }
  .header-menu{
    display:block!important;
  }

  .group-field{
    width:120px;
  }

  .secondary-parent-group .form-group-phone {
    width: 100%;
  }
}
