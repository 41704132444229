#admin-events-bookings-guardians-index {
    @media print {
        font-size: 1rem;

        h1 {
            margin-top: -2rem;
            font-size: 3rem;
        }

        .print-hide {
            display: none;
        }
    }
}