.events {
    display: flex;
    flex-direction: column;
    gap: 2em 0;

    .event {
        border: 1px solid $default-red;
        border-radius: 4px;

        h2 {
            background-color: $default-red;
            color: white;
            padding: 1em;
            margin: 0;
        }

        &-content {
            padding: 1.5em 1em 1em;
            font-size: 1.15em;

            a.btn {
                margin-top: 0.75em;
            }

            &-booked {
                margin: 1.5em 0 0.5em;
            }
        }
    }
}