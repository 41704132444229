$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* General Bootstrap Overrides */
@media (min-width: 1920px) {
  .container {
    width: 1420px;
  }
}

*{
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

// Let's define our variables here
$default-red: #db2e1d;
$default-white: #ffffff;
$secondary-red: #f03d4a;
$default-blue: #283a97;
$default-grey: #EEE;
$heading-black: #333333;
$main-black: #666666;
$course-blue: #777;
$pure-black: #000000;

/* Widths, Heights*/
$header-top-border-height: 10px;
$btn-width: 16%;
// Mixins//

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}

[x-cloak] {
  display: none !important;
}

// General classes

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none
}

.btn.btn-xs-square {
  min-width: 0;
  padding: 1px 10px;
}

.pure-black {
  color: $pure-black;
}

.white {
    color: #fff;
}

.text-underline {
  text-decoration: underline;
}

//.guardians-area {
//  .alert {
//    padding: 20px;
//    font-weight: 600;
//    font-size: 16px;
//    text-align: center;
//  }
//}

#group-full {
    position: absolute;
    right: 15px;
    top: 50px;
    width: 130px;


    img {
        object-fit:contain;
        width:100%;
        height:100%;
        -ms-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

}

.top-heading h2 {
  font-weight: bold;
}

.top-actions {
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: right;

  a {
    min-width: 16%;
    margin-left: 10px;
  }
}

.pre {
  white-space: pre-wrap;
}

.bottom-actions {
  margin-top: 15px;

  .delete {
    float: right;
    width: 35px;
  }
  .btn:last-child{
    margin-left: 20px;
  }
}

.form-group.confirm--highlight {
  background-color: $gray-lighter;
  border-left: 3px solid darken($gray-lighter, 10%);
  padding: 13px 15px 10px 15px;
  margin: 10px 15px;

  label {
    font-weight: 700;
    width: 100%;
  }

  input {
    margin-right: 5px;
  }

  &.confirm_payment_duration{
    margin-bottom: 20px;
  }

  #bookings-toc-show & {
    margin: 15px 0;
    padding: 13px 15px 10px 35px;

    input {
      left: 15px;
    }
  }
}

.dotted-separator {
  width: 70%;
  border-top: 1px dashed$default-red;
}

.background-red {
  background-color: $default-red;
}

.highlight-message {
  color: $default-red;
}

.text-white {
  color: $default-white;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.action-message {
  margin: 10px 0 20px 0;
  padding: 10px 20px;
  background-color: $default-red;
  color: #ffffff;
}

.radius {
  @include border-radius(5px);
}

.guardians-area .btn {
  padding: 20px 10px 20px 10px;

  &.btn-ae-red {

    background-color: $default-red;
    color: $default-white;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &.btn-ae-blue {
    background-color: $course-blue;
    color: $default-white;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &.btn-ae-red:hover {
    background-color: darken($default-red, 8%);
  }

  &.btn-ae-blue:hover {
    background-color: darken($course-blue, 10%);
  }

  &-xs {
    padding: 10px 20px;
  }

  &-list {
    margin-bottom: 10px;
  }
}
input{
  border-radius: 4px;
}
.form-group {
  input.required::placeholder::after,
  label.required::after {
    content: ' *';
    color: $default-red;
  }

  // Enforce .required fields for has errors
  &.has-error {

    input, select, textarea {
      border-color: #E3E3E3;

      &.required {
        border-color: $default-red !important;
      }
    }

    .help-block {
      padding: 10px;
      background-color: $default-red;
      color: #ffffff;
      @include border-radius;
    }

  }
}

.line-separator div {
  padding-top: 6px !important;
  .line {
    border-bottom: 1px dashed $default-red !important;
  }
}
.line {
  border-bottom: 1px solid $default-red;
}

// Global

a {
  color: #000000;
  &:hover {
    color: #000000;
    text-decoration: none;
  }

  &.disabled {
      opacity: .3;
      cursor: not-allowed;
  }
}
#expand-medi-click{
  span{
    display: block;
    background-color: $default-red;
    border-color: 1px solid $default-red;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    text-align: center;
    &.active{
      background-color: transparent;
      color: $default-red;
    }
  }

}
body {
  font-family: Verdana, Geneva, sans-serif;
  color: $main-black;
}

h2, h3 {
  font-size: 16px;

  &.lead {
    font-size: 24px;
  }
}

h4 {
  font-size: 14px;
  margin: 20px 0 10px;
}

h1,h2,h3,h4,h5,h6 {
  color: $heading-black;
}

div.main-content {
  //padding-top: 20px;
  padding-bottom: 20px;
  min-height: 70vh;

  .main-content-title {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
  }
  .course-content-title{
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  .bookings-content-title{
    margin-top: 45px;
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .tandc{
    .main-content-title{
      margin-bottom: 15px;
    }
    .hoz-line{
      margin-bottom: 30px;
    }
    margin-bottom: 15px;
  }

}

.padding-top{
  margin-top: 8px;
  display: inline-block;
}

.tandcTitle{
  h2{
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  margin-bottom: 40px;
}
.tandcSubmit{
  margin-bottom: 30px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently                                  not supported by any browser */
}

.form-group label {
  font-weight: normal;
  font-size: 16px;
}
/*
   * Button styles
   */

.btn{
  min-width: 100px;
  border-radius: 4px;
  font-weight: bold;
}
.btn-icon {
  i {
    margin-right: 5px;
  }
}
.btn-disabled {
  opacity: 0.5;
  cursor: default;
}
.btn-onlyIcon {
  min-width: 0;
  padding: 3px 10px;
  margin: -3px 0;
}
/* Header */

.clear {
  clear: both;
}

.result{
  p{
    margin-top: 10px;
    color: $pure-black;
  }
  .input {
    border: 1px solid #999;
    border-radius: 4px;
    display: block;
    padding: 10px;
    margin-top: 8px;
  }
}

div#expand-medi {
  transition: 1s ease;
  overflow: hidden;
  //height: 0px;
  &.open{
    //height: 100%;
  }
}
.header-logo {
  position: relative;
  background-image: url(/img/logo@2x.png);
  background-repeat: no-repeat;
  background-size: 119px;
  margin-top: 10px;
  width: 112px;
  height: 100px ;
  ///
  float: left;
  text-align: center;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    font-size: 0;
    color: transparent;
  }
}

.select-teacher, .time-slot, .submit-area{
  //padding: 30px;
  input.submit{
    text-align: right;
  }
  h3{
    font-weight: bold;
  }
  p, h3{
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
  p{
    margin-top: 10px;
  }

  &>div.select {
    margin-top: 10px;
    margin-bottom: 20px;

    select {
      min-width: 285px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px;
      border-radius: 4px;
      display: inline-block;
      float: right;
      vertical-align: middle;
    }
  }
  &>div.radio {
    margin-top: 10px;
    margin-bottom: 20px;
    display:flex;
    flex-flow: row wrap;
    flex-direction:row;
    //justify-content: space-between;

    input[type="radio"]:checked + label p{
      background-color: red;
      color: white;
      border-color: red;
    }
    .title{
      flex:0 0 100%;
    }
    label  {
      padding-left: 0px;
      flex:0 1 auto;
      p {
        border: 1px solid;
        border-color: #333;
        border-radius: 4px;
        padding: 5px 20px;
        font-size: 14px;
        font-weight: bold;
        color: #333;

        &.disabled{
          color: rgba(51, 51, 51, 0.42);
          border-color: rgba(51, 51, 51, 0.42);
          position: relative;
          display: flex;
          align-items: center;

          &:hover{
            cursor: not-allowed;

            .fadeIn{opacity:1;}
            .fadeOut{opacity: 0;}
          }

          .fadeOut{    min-width: 80px;
            display: inline-block;
            opacity: 1;

            text-align: center; transition: all ease-in-out 0.3s;}
          .fadeIn{
            position: absolute; opacity: 0; transition: all ease-in-out 0.3s;
            left: 0px;
            width: 100%;
            text-align: center;}
        }
      }

    }
    input{
      visibility: hidden;
      position: absolute;
    }
  }
}
.select-teacher{
  input[type="submit"] {
    display: none;
  }
    #selectTeacher {
      display: inline-block;
      float: right;
      width: auto;
  }
}

.evening-red-box {
  border: 1px dashed red;
  border-radius: 3px;
  margin: 0 0 30px;
  &:after, &:before{
    content: '';
    clear: both;
  }
  &>div {
    vertical-align: middle;
  }
  div.redlogo {
    position:relative ;
    width: 90px;
    min-height: 80px;
    padding: 0px;
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      background-image: url(/img/redimg.jpg);
      background-size: contain;
      background-color: #ee222a;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px 0px 0px 4px;
      width: 100%;
      height: 100%;
    }
  }
  div.evening-text{
    width: calc(100% - 100px);
    display: inline-block;
    height: auto;
    p {
      padding: 0 20px;
      font-size: 16px;
      margin: 0px;
      line-height: normal;

      display: inline-block;
      vertical-align: middle;
    }
  }
}

.submit-area {
  text-align: right;
  input.submit {
    font-size: 18px;
    background-color: $default-red;
    border-radius: 4px;
    padding: 7px 24px;
    color: #FFF;
    -webkit-appearance: none;
    border: none;
  }
}



.header-contents {
  width: 100%;
  float: left;
}
.header-title {
  float: left;
  margin-top: 40px;
}
.header-title h1 {
    margin: 0;
}

.header-menu-container {
  position: relative;
  margin-bottom: 10px;
}

.menu {

  .header-menu-right {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase;

    .header-menu-list-right {
      margin: 0px;
      position: relative;
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      li {
        white-space: nowrap;
      }
    }
  }
}


.header-menu-list-right ul {
  margin: 0;
  list-style-type: none;
}

.header-menu-list-right li {
  display: inline-block;
}
.header-menu-list-right li a {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  margin-right:20px !important;
  @include transition(all 0.5s ease-in-out);
}

.header-menu-list-right li a:hover {
    color: $heading-black;
}

.header-title h1 {
  font-size: 20px;
}

.header-menu-right {
  float: right;
}

.header-menu{
  h2{
    font-size: 22px;
    padding-bottom: 10px;
  }
  li.header-separator {
    display: block;
    height: 3px;
    margin-top: 12px;
  }
}
.header-menu-list {
  text-align: center;
}

.header-menu-list{
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 0px;


  li {
    display: inline-block;
    height: 25px;
    font-size: 16px;
    list-style-type: none;
    border-right: 1px solid $default-red;
    position: relative;
    &:first-of-type a{
      padding-left: 0px;
    }
    &:last-of-type , &.no-right-border{
      border-right: 0;
      a{
        padding-right: 0px;
      }
    }

    a {
      font-weight: bold;
      line-height: 25px;
      padding: 10px 14px;
      font-size: 16px;
      position: relative;
      color:#838383;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $course-blue;
        &:before {
          left: -5px;
          right: 0;
        }
      }
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 50%;
        right: 50%;
        top: 0;
        background: $course-blue;
        height: 2px;
        -webkit-transition-property: left, right;
        transition-property: left, right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      &.disabled {
        &:before {
          display: none;
        }
      }
    }
  }
}

.main-inner-container {
  margin-left: -37px;
}

.header-separator {
  height: 3px;
  background-color: $default-red;
}

/* Mobile Header */

.mobile-menu-button {
  display: none;
  font-size: 32px;
}

.header-menu-mobile-show {
  display: block !important;
  position: fixed;
  z-index: 5;
  top: $header-top-border-height;
  right: 0;
  width: 66.66667%;
  margin-right: -30px;
  background-color: #ffffff;
  padding: 10px 0;
  border-bottom: 8px solid $default-red;

  li {
    float: none;
    display: block;
    padding-left: 25px;
    border-right: 0;
    text-align: left;
    margin-bottom: 15px;

    &:first-of-type {
      margin-top: 10px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      position: absolute;
      width: 100%;
      border-left: 1px solid #ee1b2a;
    }
  }

  & + .mobile-menu-button {
    display: none;
  }
}

/* Footer */

footer {
  display: block;
  margin-top: 20px;
  clear: both;
}

.footer {
  padding: 35px 0px;
  background-color: #db2e1d;
  color: #ffffff;
  font-size: 12px;
  line-height: 23px;
}
.copyright{
  float: right;
}

.footer p {
  margin: 0;
}

.footer a {
  font-size: 12px;
  color: #ffffff;
}

.subfooter {
  padding-top: 10px;
	font-size: 10px;
  margin-bottom: 20px;
}

.footer-menu-list-right {
	margin: 0;

}

.footer-menu nav {
  float: right;
}

.footer-menu-list-right li {
  float: left;
  padding: 0 20px;
  list-style-type: none;
  border-right: 1px solid #000000;
  padding: 0 20px;
}
.footer-menu-list-right li:first-of-type {
	padding-left: 0;
}
.footer-menu-list-right li:last-of-type {
  border-right: 0;
  padding-right: 0;
}

.footer-menu-list-right li a {
  color: #000000;
}

body#admin-courses-groups-waiting_list {
  .table-hover > tbody > tr:hover {
    background-color: #98f5a2;
  }
}

// Register page
body#register {

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: $default-red;
  }

  a{
    color: $default-red;
  }

  input.form-control + input.form-control {
    margin-top: 20px;
  }

  .login-details {
    h1{
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      padding-bottom: 20px;
    }
    .form-group.has-error .help-block {
      margin-top: 25px;
    }
  }

  .account-information {
    text-transform: uppercase;
    padding: 0px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0px;

    @include border-radius;
  }

  .form-group-phone {
    float: right;
  }

  label.confirm-sms {
    margin-top: 16px;
  }

  div.secondary-parent-group > div.orm-group {
    display: none;
  }

  div.registration-actions {
    &.has-error label {
      margin-bottom: 0;
    }

    input.submit {
      float: right;
      width: $btn-width;
    }
    label {
      display: block;
      margin: 20px 0;
    }
    div.form-group.has-error {
      margin-bottom: 0;
    }
  }

}

.h-auto{
  height:auto!important;
}

.txt-r{
  text-align:right!important;
}

.block{
  display:block!important;
}
#course-groups-index{
  .course-filters * {
      display: inline-block;
  }
  .filter-btn{
    position:absolute;
    bottom:0;
    right:15px;
  }
}

.red-box{
  padding-top:40px;
  padding-bottom:40px;
  border:2px dashed $default-red;
  color:$default-red;
}

.bold{
  font-weight:600;
  a{
    white-space: nowrap;
  }
}

#course-groups-choice {
  .choice-slot {
    &--previous {
      margin: 0 0 30px;
    }
  }

  .btn {
    margin-top: 10px;
  }
}

#bookings,
#waiting-list {

  .booking-item{
      margin-bottom: 20px;
      color:#000;
      height:auto;
      cursor:pointer;

      img {
          height: 180px;
          width: 100%;
          object-fit: cover;
          border: 2px solid silver;
          border-right: none;
      }

      .border-right{
        border-right: 2px solid $default-red;
        margin-bottom: 20px;
      }

    .payment-information{
        color: $default-red;
        cursor: pointer;
      }

      .payment-information:hover, .payment-information:active{
        color: $course-blue;
      }

      .booking-title h2{
        margin-top:0;
        margin-bottom:0;
      }

      .booking-name{
        margin-bottom:10px;
      }


    hr {
      border-top: 2px dashed $default-red;
      margin-top: 10px;
      }

    .booking-title{
      padding-top:10px;
    }
  }

  .desktop-payment{
    display:block;
  }

  .mobile-payment{
    display:none;
  }

  .btn-ae-white{
    float: right;
    font-size: 14px;
    margin-top: 10px;
  }

  .date-booked{
    font-size:13px;
    margin-top:10px;
  }
  .hide-content:before {
    content: 'Hide ';
  }

  .pay-info{
    display:none;
  }

  .introduction-container{
    margin-bottom:30px;
    margin-top:10px;
    color:#000000;
  }

  .sum-title{
    margin-bottom:20px;
    font-weight:600;
  }

  .payment-info-container{
    //border:2px solid silver;
  }


}

/* Login Page */
body#login .main-content {
 img {
      margin: 0 auto;
      display: block;
      width: 100%;
   border-radius: 10px;
  }

  .helpers {
    padding-top: 10px;
    padding-bottom: 10px;
    a {
      font-size: 18px;
      &:last-of-type {
        float: right;
      }
    }
  }
}

body#login div.form-group.email {
  margin-top: 20px;
}

body#login div.form-group.login-bottom {
  margin-top: 20px;
  text-align: right;


}

/* Dashboard */
body#dashboard h1 {
}

/* Welcome Page */

body#welcome-page .main-content {
  text-align: center;
  margin-bottom: 40px;

  .main-image {
    width: 100%;
    max-width: 550px;
    margin: 40px auto 0px auto;
    div {
      display: block;
      border-radius: 20px;
      background: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      &:after{
        content: '';
        padding-top: 70%;
        display: block;
      }
    }
  }

  p.question {
    margin: 30px 0 30px 0;
    font-weight: bold;
    font-size: 24px;
  }

  .choices a.btn {
    width: $btn-width;
    color: #ffffff;

    &:first-of-type {
      margin-right: $btn-width;
    }

    &:hover {
      color: #ffffff;
    }

  }

  .row-no-gutters {
    margin: 0 -10px;
  }

  #parents_evening {
    .icon-container {
      img {
        margin-top: -10px;
        margin-bottom: 0px;
      }
    }

    .icon-container-alt {
      img {
        margin-top: 35px;
        margin-bottom: 15px;
      }
    }
  }

  .tile {
    display: block;
    height: 300px;
    margin: 10px;

    &-disabled {
      opacity: .4;
      cursor: not-allowed;

      &:hover {
        .overlay.blue {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

/* Home Portal */
#portal-not-active {
  .action-banners {
    img {
      width: 100%;
      height: auto;
    }
    &> div:nth-child(2n+3) {
      padding-left: 5px;
      padding-top: 10px;
    }
    &> div:nth-child(2n+2) {
      padding-right: 5px;
      padding-top: 10px;
    }
  }
}

/* Change Password Code */

#change-password {
  .change-password-actions .change-password-button {
    width: $btn-width;
    float: right;
  }
}

/* Children index */

body#children {
  .child {
    font-size: 14px;

    .inner-wrapper {
      padding: 20px 30px;
      border: 1px solid #5a5a5a;
      @include border-radius;
      @include transition(all 0.3s ease-in-out);

    }

    .descriptor {
      color: $heading-black;
      font-size: 14px;
    }

    div.row > div {
       padding-top: 20px;
      font-size: 16px;
    }

    div.child-header > div {
      padding-top: 0;
    }


    div.row > div:nth-child(even) {
      text-align: left;
    }

    .child-header {
      a.disabled {
        &:hover {
          color: $default-red;
        }
      }
    }

    .child-header, .child-header h2 {
      font-size: 18px;

      .edit{
        font-weight: bold;
        text-align: right;
        font-size: 17px;
        margin-left: 30px;
        float: right;
        text-transform: uppercase;
        color: $default-red;
        @include transition(all 0.5s ease-in-out);
        &:hover{
          color: $pure-black;
        }
      }
    }

    h2.child-name {
      margin: 0;
      font-weight: bold;
      padding-left: 5px;
    }
  }

  .children-actions {
    margin: 20px 0 0 0;

    .edit {
      float: left;
      width: $btn-width;
    }
  }

  .children-seperator{
    margin-top:10px;
    margin-bottom:20px;

    hr{
      border-top: 2px solid #E6E2E2;
    }
  }

}

/* My Profile - Show */
body#my-profile {
  .my-profile-actions {
    text-align: right;

    a {
    margin-top: 10px;
    }
  }

  h2.secondary-parent {
    display: inline-block;
    cursor: pointer;

    span {
      font-size: 26px;
    }
  }

  .phone-2 .highlight-message {
    font-size: 11px;
    padding-left: 25px;
  }

  #secondary-parent-info {
    display: none;
  }

  table {
    margin-bottom: 0;
    width: 100% !important;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .embed-responsive {
    margin-bottom: 20px;
  }
}

/* My Profile - Edit */
body#my-profile-edit {
  h2 {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 20px;
  }

  input.form-control + input.form-control {
    margin-top: 20px;
  }

  .my-profile-edit-actions {
    padding-top: 10px;
    text-align: right;
  }
}



/* Courses Styling */


#course-groups-index{
  hr{
    margin-top:0;
    margin-bottom:20px;
    border-top:1px solid #999999;
  }
}

.flr{
  float:right;
}

.fll{
  float:left!important;
}

.pdf-link{
  color:$pure-black;
  font-weight: bold;
}

@import 'components/course';
@import 'components/homework';

.btn.wide {
    padding-left: 40px;
    padding-right: 40px;
}
#courses-index .form-control {
    display: inline-block;
    width: 200px;
}
.course-filters {
    margin: 20px auto 40px auto ;
  form {
    width: 100%;
  }
  select{
    width: 25%;
  }

}


.no-padding{
  padding:0;
}

.accordion-toggle {

  &:hover, &:active, &:focus{
    color: #FFFFFF;
  }

}

.guardians-area {

  //.btn:hover, .btn:active {
  //    background-color: #777!important;
  //    transition: all 0.4s ease-in-out;
  //}

  .btn-ae-white:hover{
    background-color:transparent!important;
  }

  .btn-ae-white a{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &#child-add, #child-edit {

    label span.this-child {
      font-weight: 800;
    }

  }

}
.teach-slots {
  text-align: center;
  .box-teach-slot {
    border: 1px solid #333;
    border-radius: 4px;
    width: 100%;
    list-style: none;
    padding: 0px;
    text-align: left;
    li.box-row {
      border-bottom: 1px solid #999;
      &:last-of-type{
        border-bottom: none;
      }
      p {
        position: relative;
        padding-left: 15px;
        display: inline;

      }
      span {
        display: inline-block;
        position: relative;
        color: #fff;
        padding: 10px 20px;
        margin-right: 10px;

        &:after {
          content: '';
          background-color: #333;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: -10;
        }
      }
      a {
        display: inline-block;
        float: right;
        position: relative;
        color: #fff;
        padding: 0px 10px;
        margin: 10px;

        &:after {
          content: '';
          background-color: #ffb32f;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: -10;
          padding: 10px;
          border-radius: 4px;
        }
      }
    }
  }
}
/*Forms */
.form-control{
  height: 42px;
  border-radius: 0px;
 // border-color: #777;
}

.form-group--checkbox {
  display: flex;

  input[type=checkbox].form-control {
    width: 20px;
    height: 20px;
    display: inline-block;
    box-shadow: none;
    margin-top: 0;
    margin-right: 10px;
  }
}
/* Groups */

.group-item{
  margin: 0;
    margin-bottom: 20px;
  border-radius: 10px;
    border: 1px solid $default-red;
    color:#000;
    height:200px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor:pointer;

    img{
      height:100%;
      width:100%;
      object-fit:cover;
      border-radius: 6px 0px 0px 6px;
    }
}


// parernt evening

.parent-evening-item{
  margin: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid $default-red;
  color:#000;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor:pointer;

  .redimg {
    //background-color: #ee222a;
    border-radius: 10px 0px 0px 10px;
    img {
      height: 60%;
      width: 100%;
      object-fit: cover;
      border-radius: 6px 0px 0px 6px;
      margin: auto;
      display: block;
      max-width: 160px;
    }
    p{
      margin: -15px 0 0px;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }
  hr.dotted{
    margin-top: 5px;
    border-top: 1px dashed $default-red;
  }

  .group-content{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      background-color: $default-red;
      width: 33.8%;
      height: 100%;
      left: -33.5%;
      top: 0px;
      z-index: -1;
      border-radius: 10px 0px 0px 10px;
    }
    .btn.square{
      border-radius: 5px;
    }
  }

}

.places{
  float:right;
}

.booking-form {
  border:1px solid $default-red;
  border-top: 4px solid $default-red;
  border-bottom: 4px solid $default-red;
  border-radius: 4px ;
  margin-bottom: 25px;
  margin-top: 10px;
  padding: 40px 50px 30px 50px;

  ul {
    li {
      margin: 7px 0;
    }
  }

  .form-group--spaced {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  hr {
    margin: 30px 0;

    &.half-top {
      margin-top: 10px;
    }
  }
}

.booking-form-message {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-top: 20px;
  margin-bottom: 4px;
}

.inline-message {
  margin-top: -8px;
  margin-left: 20px;
}

.group-content{
  padding:15px;
}
.medical-title{
  margin-top: 40px;
}
#medical-info{
  label{
    font-weight: 100;
    input[type='text'], input[type='number']{
      height: 30px;
    }
  }
  .half-text, .half-text input  {
      width: 100%;
    input[type='text'] {
      margin-bottom: 10px;
    }
  }

  .form-group {
    margin-bottom: 25px;
  }

  .medi-title{
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .medi-prefilled-info{
    padding-top: 15px;
    padding-bottom: 15px;

    &>div {
      padding-top: 10px;
      padding-bottom: 10px;
      &:after {
        content: '';
        width: 100%;
        padding-top: 10px;
        border-bottom: 1px solid #eee;
        display: block;
      }
    }
  }
  .half{
    display: block;
    vertical-align: top;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    input[type='checkbox']{
      position: absolute;
      left: 0px;
    }
    input[type='text']{
      width: 100%;

    }
  }
  .full{
    padding-top: 20px;
    padding-bottom: 20px;
    textarea {
      width: 100%;
    }
  }
  .medi-guardian-info{
    .margin-10{
      margin-top: 10px;
      label{
        margin-top: 0px;
        padding-top: 0px;
        input[type='text']{
          margin-top: 0px;
        }
      }
    }
    label{
      font-weight: 100;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      input[type='text'], input[type='number']{
        display: block;
        width: 100%;
        margin-top: 10px;
      }
    }
    .address{
      input:last-child {
        margin-top: 13px;
      }
    }
    .checklist-medi{
      position: relative;
      padding-left: 20px;
      input[type='checkbox']{
        position: absolute;
        left: 0px;
      }
    }
  }
}
#children .medi-info-checklist .row, #children .row.margin-10{
  border-bottom: 1px dashed red;
  padding-top: 5px;
}
#children #medical-info .half{
  padding-left: 0px;
}
#children .medi-info-checklist .row:nth-last-child(2),
#children .medi-info-checklist .row:last-child,
#children .row.margin-10:last-child{
  border-bottom: none;
}


.btn.btn-ae-red.right{
  float: right;



}




.group-content .btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.group-title p {
    display: inline-block;
    font-weight: bold;
}

.underline{
  height:2px;
  background-color:$default-red;
  margin-bottom:15px;
  padding:0 15px;
}


.summary-image img{
  height:100%;
  width:100%;
  object-fit:cover;
}

.summary-section {
    color: #000;
}

.summary-section p{
  color:#666666;
}

.smallprint {
    font-size: 12px;
    color: $default-red;
}

.summary-section h2{
  font-weight:600;
}

.summary-column h2 {
    border-bottom: $default-red dashed 1px;
    padding-bottom: 15px;
    margin-top: 0;
}

.summary-column{
  margin-bottom:30px;
}


.tuition-title{
    border-bottom: 1px dashed $default-red;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .tuition-title *{
    font-weight:600;
  }

.vert-line{
  border-left:1px dashed $default-red;
  min-height:65px;
}


.checkpoints{
  padding-top: 15px;
  padding-bottom: 15px;
  .form-group{
    position: relative;
    padding-left: 20px;
    label {
      font-size: 14px;
      input {
        position: absolute;
        left: 0px;
      }
    }
  }
}
.inline img{
  display: inline;
  max-width: 120px;
}

.terms {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 20px;

  border: 1px solid $default-red;
  border-top: 4px solid $default-red;
  border-bottom: 4px solid $default-red;
  h2{
    font-weight: bold;
  }
}

.main-content-title.btn-ae-red{
  cursor:default;
  font-size: 17px;
  font-weight: 500!important;
  margin-bottom: 20px;
  padding: 8px 20px;
  font-weight: 500;
}

.main-content-title.btn-ae-red:hover{
  background-color:$default-red;
}

.btn.btn-ae-white {
    background-color: #ffffff;
    border: 1px solid #ee1b2a;
    padding: 5px 30px;
    font-size: 17px;
  border-radius: 3px;
}

.form-inline{
  .form-control {
    border-radius: 3px;
    border-color: #333;
    min-width: 100px;
  }
  .btn-square{
    margin-top:20px;
  }
}

.btn-square{
  padding: 7px 20px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  -webkit-appearance: button-bevel !important;
}

.btn.btn-ae-white a {
    color: #ee1b2a;
    font-weight: 500;
    text-transform: uppercase;
}

.hoz-line{
  height: 2px;
  background-color: #ee1b2a;
  margin-bottom:20px;
}


.login-section{
  padding-top:30px;
  h2{
    font-size: 24px;
    font-weight: bold;
    //text-align: center;
    margin-bottom: 15px;
  }
}

.pdf-link:hover{
  color:$course-blue;
}

.spacer{
  margin-top:80px;
  margin-bottom:80px;
}

.home-content {
    padding: 30px 0px;
  .row {
    margin: 0px;
  }

  #homework {
    .icon-container {
      top: 60px;
    }
  }
}

.home-background{
  background-size:cover;
  background-repeat:no-repeat;
  height:100%;
  width:100%;
  position: relative;
}

.home-background h1{
  font-size:30px;
  margin-top:10px;
}

.overlay.blue,
.overlay:hover {
    background-color: rgba(40, 58, 151, 0.56);
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.icon-container{
  position: relative;
  top: 40px;
  left: 0;

  .icon-container-parents-eve {
    position: relative;
    top: 87px;
    left: 0; }

  h3 {
    margin-top:0;
  }

  i {
    color: white;
    font-size: 90px;
    margin: 40px 0 20px;
  }
}

.group-field{
  width:180px;
  display:inline-block;
}

.red{
  display:inline-block;
  height: 30px;
  width: 3px;
  background-color: $default-red;
  margin: 0 20px;
  position: relative;
  bottom: -10px;
}

.support-intro .title{
  margin-bottom:15px;
}




/* My Profile */

#profile-section{

  h3{
    margin-top:0;
    font-weight:600;
    font-size:14px;
  }

  h2{
    margin-bottom:30px;
  }

  span{
    font-size:15px;
  }
}




/* Support page */

.contact-item {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
    position: relative;
}

.contact-container {
    margin-top: 20px;
    margin-bottom: 50px;
}

#contact-1{
  padding-right:5px;
}

#contact-2{
  padding-left:5px;
}

.FAQ .title{
  margin-bottom:20px;
  color:#000000;
}



#support{

  .panel-heading {
    background-color: $default-red;
    color:$default-white;
  }

  .icon-container h1 {
    margin-top: 0;
  }

  .panel-title{
    padding-left:13px;
  }

  .panel-body{
    padding-left:50px;
    border:none;
  }

  .panel-heading{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  i {
    position: relative;
    //left: -15px;
  }

  a:focus {
    text-decoration: none;
  }

}

#bookings-confirmed, #register-activation-required{
  .confirmed-container{
      height:400px;
      display:block;
      margin-bottom:30px;

      .red-banner{
        width:100%;
        background-color:$default-red;
        position:absolute;
        bottom:40px;
        color:$default-white;
        padding-left:40px;
        padding-top:10px;
        padding-bottom:10px;
      }

    .confirmed-image{
      height:100%;
      width:100%;
      background-image:url('/img/success2.jpg');
      background-size:cover;
      position:relative;
      background-position:50%;
    }
  }

  .indent{
    padding-left:50px;
  }

  .email{
    margin-top: 30px;
    margin-bottom: 30px;
    color: $default-red;
    font-size: 18px;
    font-weight: 600;
  }

  .pdfs{
    list-style:none;

    a{
      color: rgb(42, 75, 247);
    }

    a:hover{
      text-decoration:underline;
    }

  }



  h1{
    font-size:30px;
  }

  p{
    margin-bottom:20px;
    margin-top:20px;
  }

  .home-btn{
    display: block;
    margin-bottom: 15px;
    margin-top: 30px;
  }
}

#bookings-confirmed {
  h1 {
    font-size: 18px;
  }

  p.payment-confirmation {
    font-size: 12px;
    margin-top: 0;
  }

  p.margin-bottom-5 {
    margin-bottom: 5px;
  }

  p.email {
    color: $default-red;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.margintop20{
  margin-top: 20px;
}
.marginbottom20{
  margin-bottom: 20px;
}
.capitalise{
  text-transform:capitalize;
}

#my-profile-change-password{

  .main-content{
    padding-top:40px;
  }
}


#waiting-list{
  .arrow-image {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;

      img{
        height:100%;
        width:100%;
      }
    }

  hr {
    border-top: 2px dashed $default-red;
    margin-top: 10px;
    }

    .course-change{
      color:$default-red!important;
    }

    .waiting-list-item{
      border:2px solid silver;
      margin-bottom:20px;
    }

    .xs-hide{
      display:block;
    }

    .xs-show{
      display:none;
    }


}





@media(max-width:480px){
    .course-filters {
      text-align: center;
    }

    #courses-index .btn.wide{
      float:none;
      margin-bottom:20px;
    }
    #courses-index .form-control {
      width: 100%;
      margin-bottom: 15px;
      margin-top: 15px;
  }
  .group-content .btn {
      position: relative;
      display: block;
      float: none;
      bottom: 0;
      right: 0;
      margin-top: 30px;
      margin-bottom: 15px;
  }


  .group-field {
    width: 100%!important;
    margin-bottom: 10px;
  }

  .red{
    display:none!important;
  }

  .summary-image {
    margin-bottom: 30px;
    img{
      border-radius: 5px;
    }
  }


  #bookings {
      .booking-item {
      margin-bottom: 20px;
      color: #000;
      height: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
}

  #course-groups-index .filter-btn{
    display:block;
    position:relative;
    right:0;
    left:0;

    .btn{
      text-align: center;
      float: none;
      margin-left: auto;
      margin-right: auto;
      display: block;
      }
    }

  body#children {
    .child div.row > div:nth-child(even) {
      text-align: left;
    }

    .children-actions{
      text-align:center;
    }

    .children-actions .edit {
      float: none;
      width: 50%;
    }
  }




#bookings-confirmed{
  .red-banner{
    padding-left:0!important;
    text-align:center;
    bottom:-10px!important;
  }
  .confirmed-container{
    height:270px;
  }
  .confirmed-content {
    text-align: center;
  }
  .pdfs{
    padding-left:0;
  }
}


}




/*empty box style*/
.message-box{
  border-radius: 6px;
  border: #ee1b2a 1px dashed;
  //border-top-width: 8px;
  padding: 20px 15px;
  text-align: center;
  h1{
    padding-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  h2{
    font-size: 20px;
    padding-bottom: 30px;
  }
  h1.no-h2{
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

body.admin-area {
  margin-top: 80px;
}

body.admin-area {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;

    /* Do not add margin bottom on modal titles and panel titles */
    &.modal-title, &.panel-title {
      margin-bottom: 0;
    }
  }
  label {
    font-size: inherit;
  }

  h2{font-size:18px ;font-weight:bolder}

  .search-wl-group {
    text-align: right;
    line-height: 69px;
  }

  table tr th:last-child,
  table tr td:last-child {
    text-align: right;
  }

  &#admin-guardian_errors-index,
  &#admin-courses-index {
    li {
      form {
        button {
          background-color: transparent;
          border: 0;
          padding: 5px 15px;
          width: 100%;
          text-align: left;
        }
      }

    }
  }

}

#admin-waiting_list-index.admin-area {
  table tr th:last-child,
  table tr td:last-child {
    text-align: left;
  }
}

.hover-active-blue{
    background-color:$course-blue!important;
}

#welcome-page .main-content {
    padding-top: 0px;
}

#admin-courses-edit {
  .course-image img {
    max-width: 100%;
    object-fit: initial !important;
  }
}

.waiting-place{
  margin-top:15px;

  p{
    font-weight:bold;
    color:#000;
  }
}


#course-groups-waiting-list .summary-image {
    margin-bottom: 20px;
}

.group-locked span.glyphicon.glyphicon-lock {
  color: green;
  &.locked {
    color: crimson;
  }
}

.group-locked > li > form > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap;
  text-align: center;
}

.spacing{
  margin-top:10px;
  margin-bottom:20px;
}
@media (max-width: 1280px) {
  body.admin-area {
    .navbar-header {
      float: none;
    }
    .navbar-toggle {
      display: block;
    }
    .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-collapse.collapse {
      display: none !important;
    }
    .navbar-nav {
      float: none !important;
      //margin: 7.5px -15px;
    }
    .navbar-nav > li {
      float: none;
    }
    .navbar-nav > li > a {
      //padding-top: 10px;
      //padding-bottom: 10px;
    }
    .navbar-text {
      float: none;
      margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
      display: block !important;
    }
    .collapsing {
      overflow: hidden !important;
    }
  }
}
//
// Colour customisation
//
// `!default` allows overriding variables that are defined before @import
//
body.admin-area {

  #exTab2{
    margin-top:30px;
  }

  .navbar-header{

    display: block;
    width: 100%;


  }

  .nav{
   // margin-top:10px;

  }

  .nav > li > a{
   // padding:10px !important;
  }

  // Border between the header (and footer) and the table body
  $table-header-border: 1px solid #111111 !default;

  // Border of rows / cells
  $table-body-border: 1px solid #dddddd !default;

  // Row background colour (hover, striping etc are all based on this colour and
  // calculated automatically)
  $table-row-background: #ffffff !default;

  // Row colour, when selected (tr.selected)
  $table-row-selected: #b0bed9 !default;

  // Text colour of the interaction control elements (info, filter, paging etc)
  $table-control-color: #333333 !default;

  // Highlight colour of the paging button for the current page
  $table-paging-button-active: #dcdcdc !default;

  // Hover colour of paging buttons on mouse over
  $table-paging-button-hover: #111111 !default;

  // Colour to use when shading
  $table-shade: black !default;

  // jQuery UI stylesheet imports this one - there are just two places where we
  // don't want DataTabels default styles assigned for jQuery UI, so rather than
  // duplicating the whole file, this is the best option
  $jqueryui: false !default;

  //
  // Functions / mixins
  //
  @function tint( $color, $percent ) {
    @return mix(white, $color, $percent);
  }

  @function shade( $color, $percent ) {
    @return mix($table-shade, $color, $percent);
  }

  @mixin gradient( $from, $to ) {
    background-color: $from;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $from 0%, $to 100%); /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, $from 0%, $to 100%); /* FF3.6+ */
    background: -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
    background: -o-linear-gradient(top, $from 0%, $to 100%); /* Opera 11.10+ */
    background: linear-gradient(to bottom, $from 0%, $to 100%); /* W3C */
  }

  /*
   * Table styles
   */
  table.dataTable {
    font-size: 12px;
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;

    /*
     * Header and footer styles
     */
    thead,
    tfoot {
      th {
        font-weight: bold;
      }
    }

    thead th,
    thead td {
      padding: 10px 18px;

      @if not $jqueryui { // jQuery UI defines its own border
        border-bottom: $table-header-border;
      }

      &:active {
        outline: none;
      }
    }

    tfoot th,
    tfoot td {
      padding: 10px 18px 6px 18px;

      @if not $jqueryui { // jQuery UI defines its own border
        border-top: $table-header-border;
      }
    }

    // Sorting
    @if not $jqueryui { // jQuery UI defines its own sort icons
      thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
          cursor: pointer;
          *cursor: hand;
          background-repeat: no-repeat;
          background-position: center right;
        }

        .sorting {
          background-image: url('/img/sort_both.png');
        }
        .sorting_asc {
          background-image: url('/img/sort_asc.png');
        }
        .sorting_desc {
          background-image: url('/img/sort_desc.png');
        }
        .sorting_asc_disabled {
          background-image: url('/img/sort_asc_disabled.png');
        }
        .sorting_desc_disabled {
          background-image: url('/img/sort_desc_disabled.png');
        }
      }
    }

    /*
     * Body styles
     */
    tbody {
      tr {
        background-color: $table-row-background;

        &.selected {
          background-color: $table-row-selected;
        }
      }

      th,
      td {
        padding: 8px 10px;
      }
    }

    // Stripe classes - add "row-border" class to the table to activate
    &.row-border tbody,
    &.display tbody {
      th, td {
        border-top: $table-body-border;
      }

      tr:first-child th,
      tr:first-child td {
        border-top: none;
      }
    }

    // Stripe classes - add "cell-border" class to the table to activate
    &.cell-border tbody {
      th, td {
        border-top: $table-body-border;
        border-right: $table-body-border;
      }

      tr th:first-child,
      tr td:first-child {
        border-left: $table-body-border;
      }

      tr:first-child th,
      tr:first-child td {
        border-top: none;
      }
    }

    // Stripe classes - add "stripe" class to the table to activate
    &.stripe tbody,
    &.display tbody {
      tr.odd {
        background-color: shade($table-row-background, 2.35%); // shade by f9

        &.selected {
          background-color: shade($table-row-selected, 2.35%);
        }
      }
    }

    // Hover classes - add "hover" class to the table to activate
    &.hover tbody,
    &.display tbody {
      tr:hover {
        background-color: shade($table-row-background, 3.6%); // shade by f5

        &.selected {
          background-color: shade($table-row-selected, 3.6%);
        }
      }
    }

    // Sort column highlighting - add "order-column" class to the table to activate
    &.order-column,
    &.display {
      tbody {
        tr > .sorting_1,
        tr > .sorting_2,
        tr > .sorting_3 {
          background-color: shade($table-row-background, 2%); // shade by fa
        }

        tr.selected > .sorting_1,
        tr.selected > .sorting_2,
        tr.selected > .sorting_3 {
          background-color: shade($table-row-selected, 2%);
        }
      }
    }

    &.display tbody,
    &.order-column.stripe tbody {
      tr.odd {
        > .sorting_1 {
          background-color: shade($table-row-background, 5.4%);
        }
        // shade by f1
        > .sorting_2 {
          background-color: shade($table-row-background, 4.7%);
        }
        // shade by f3
        > .sorting_3 {
          background-color: shade($table-row-background, 3.9%);
        }
        // shade by f5

        &.selected {
          > .sorting_1 {
            background-color: shade($table-row-selected, 5.4%);
          }
          > .sorting_2 {
            background-color: shade($table-row-selected, 4.7%);
          }
          > .sorting_3 {
            background-color: shade($table-row-selected, 3.9%);
          }
        }
      }

      tr.even {
        > .sorting_1 {
          background-color: shade($table-row-background, 2%);
        }
        // shade by fa
        > .sorting_2 {
          background-color: shade($table-row-background, 1.2%);
        }
        // shade by fc
        > .sorting_3 {
          background-color: shade($table-row-background, 0.4%);
        }
        // shade by fe

        &.selected {
          > .sorting_1 {
            background-color: shade($table-row-selected, 2%);
          }
          > .sorting_2 {
            background-color: shade($table-row-selected, 1.2%);
          }
          > .sorting_3 {
            background-color: shade($table-row-selected, 0.4%);
          }
        }
      }
    }

    &.display tbody,
    &.order-column.hover tbody {
      tr:hover {
        > .sorting_1 {
          background-color: shade($table-row-background, 8.2%);
        }
        // shade by ea
        > .sorting_2 {
          background-color: shade($table-row-background, 7.5%);
        }
        // shade by ec
        > .sorting_3 {
          background-color: shade($table-row-background, 6.3%);
        }
        // shade by ef

        &.selected {
          > .sorting_1 {
            background-color: shade($table-row-selected, 8.2%);
          }
          > .sorting_2 {
            background-color: shade($table-row-selected, 7.5%);
          }
          > .sorting_3 {
            background-color: shade($table-row-selected, 6.3%);
          }
        }
      }
    }

    &.no-footer {
      border-bottom: $table-header-border;
    }

    &.nowrap {
      th, td {
        white-space: nowrap;
      }
    }

    &.compact {
      thead th,
      thead td {
        padding: 4px 17px 4px 4px;
      }

      tfoot th,
      tfoot td {
        padding: 4px;
      }

      tbody th,
      tbody td {
        padding: 4px;
      }
    }

    // Typography
    th.dt-left,
    td.dt-left {
      text-align: left;
    }

    th.dt-center,
    td.dt-center,
    td.dataTables_empty {
      text-align: center;
    }

    th.dt-right,
    td.dt-right {
      text-align: right;
    }

    th.dt-justify,
    td.dt-justify {
      text-align: justify;
    }

    th.dt-nowrap,
    td.dt-nowrap {
      white-space: nowrap;
    }

    thead,
    tfoot {
      th.dt-head-left,
      td.dt-head-left {
        text-align: left;
      }

      th.dt-head-center,
      td.dt-head-center {
        text-align: center;
      }

      th.dt-head-right,
      td.dt-head-right {
        text-align: right;
      }

      th.dt-head-justify,
      td.dt-head-justify {
        text-align: justify;
      }

      th.dt-head-nowrap,
      td.dt-head-nowrap {
        white-space: nowrap;
      }
    }

    tbody {
      th.dt-body-left,
      td.dt-body-left {
        text-align: left;
      }

      th.dt-body-center,
      td.dt-body-center {
        text-align: center;
      }

      th.dt-body-right,
      td.dt-body-right {
        text-align: right;
      }

      th.dt-body-justify,
      td.dt-body-justify {
        text-align: justify;
      }

      th.dt-body-nowrap,
      td.dt-body-nowrap {
        white-space: nowrap;
      }
    }
  }

  // Its not uncommon to use * {border-box} now, but it messes up the column width
  // calculations, so use content-box for the table and cells
  table.dataTable,
  table.dataTable th,
  table.dataTable td {
    box-sizing: content-box;
  }

  /*
   * Control feature layout
   */
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;

    // Page length options
    .dataTables_length {
      float: left;
    }

    // Filtering input
    .dataTables_filter {
      float: right;
      text-align: right;

      input {
        margin-left: 0.5em;
      }
    }

    // Table info
    .dataTables_info {
      clear: both;
      float: left;
      padding-top: 0.755em;
    }

    #users-table_paginate {
      margin-top: -10px;
      margin-left: 15px;
    }

    // Paging
    .dataTables_paginate {
      float: right;
      text-align: right;
      padding-top: 0.25em;

      .paginate_button {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
        padding: 0.5em 1em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        *cursor: hand;

        color: $table-control-color !important;
        border: 1px solid transparent;
        border-radius: 2px;

        &.current,
        &.current:hover {
          color: $table-control-color !important;
          border: 1px solid darken($table-paging-button-active, 27%);
          @include gradient(
                          lighten($table-paging-button-active, 28%),
                          $table-paging-button-active
          );
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:active {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
        }

        &:hover {
          color: white !important;
          border: 1px solid $table-paging-button-hover;
          @include gradient(
                          lighten($table-paging-button-hover, 28%),
                          $table-paging-button-hover
          );
        }

        &:active {
          outline: none;
          @include gradient(
                          lighten($table-paging-button-hover, 10%),
                          darken($table-paging-button-hover, 2%)
          );
          box-shadow: inset 0 0 3px #111;
        }
      }

      .ellipsis {
        padding: 0 1em;
      }
    }

    // Processing
    .dataTables_processing {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 40px;
      margin-left: -50%;
      margin-top: -25px;
      padding-top: 20px;

      text-align: center;
      font-size: 1.2em;

      background-color: white;
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba($table-row-background, 0)), color-stop(25%, rgba($table-row-background, 0.9)), color-stop(75%, rgba($table-row-background, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(left, rgba($table-row-background, 0) 0%, rgba($table-row-background, 0.9) 25%, rgba($table-row-background, 0.9) 75%, rgba($table-row-background, 0) 100%);
      background: -moz-linear-gradient(left, rgba($table-row-background, 0) 0%, rgba($table-row-background, 0.9) 25%, rgba($table-row-background, 0.9) 75%, rgba($table-row-background, 0) 100%);
      background: -ms-linear-gradient(left, rgba($table-row-background, 0) 0%, rgba($table-row-background, 0.9) 25%, rgba($table-row-background, 0.9) 75%, rgba($table-row-background, 0) 100%);
      background: -o-linear-gradient(left, rgba($table-row-background, 0) 0%, rgba($table-row-background, 0.9) 25%, rgba($table-row-background, 0.9) 75%, rgba($table-row-background, 0) 100%);
      background: linear-gradient(to right, rgba($table-row-background, 0) 0%, rgba($table-row-background, 0.9) 25%, rgba($table-row-background, 0.9) 75%, rgba($table-row-background, 0) 100%);
    }

    .dataTables_length,
    .dataTables_filter,
    .dataTables_info,
    .dataTables_processing,
    .dataTables_paginate {
      color: $table-control-color;
    }

    // Scrolling
    .dataTables_scroll {
      clear: both;

      div.dataTables_scrollBody {
        *margin-top: -1px;
        -webkit-overflow-scrolling: touch;

        > table > thead > tr, > table > tbody > tr {
          > th, > td {
            // Setting v-align baseline can cause the headers to be visible
            vertical-align: middle;
          }

          > th > div.dataTables_sizing,
          > td > div.dataTables_sizing {
            // Hide the element used to wrap the content in the header for
            // the body scrolling table
            height: 0;
            overflow: hidden;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }

    &.no-footer {
      .dataTables_scrollBody {
        border-bottom: $table-header-border;
      }

      div.dataTables_scrollHead > table,
      div.dataTables_scrollBody > table {
        border-bottom: none;
      }
    }

    // Self clear the wrapper
    &:after {
      visibility: hidden;
      display: block;
      content: "";
      clear: both;
      height: 0;
    }
    zoom: 1; // Poor old IE
  }
  .table{
    td{
      vertical-align: unset;
    }
  }

  // Collapse the two column display of the control elements when the screen is
  // small - the info and paging control get collapsed first as they are wider,
  // and then the length and filter controls
  @media screen and (max-width: 767px) {
    .dataTables_wrapper {
      .dataTables_info,
      .dataTables_paginate {
        float: none;
        text-align: center;
      }

      .dataTables_paginate {
        margin-top: 0.5em;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .dataTables_wrapper {
      .dataTables_length,
      .dataTables_filter {
        float: none;
        text-align: center;
      }

      .dataTables_filter {
        margin-top: 0.5em;
      }
    }
  }
}

.glyphicon.mobile-menu-button.glyphicon-plus{
  transform: rotate(45deg);
}
.medi-info-checklist{
  .required-field {
    color: red;
    padding: 0px;
    margin: 0px;
    margin-top: -12px;
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
  }
}
.required-field {
  color: red;
  font-size: 28px;
  line-height: 1rem;
  font-weight: 700;
  display: inline-block;
  transform: translateY(8px);
}
[readonly] {
  background-color: rgb(235, 235, 228);
  border: 1px solid rgb(167, 167, 167);
}

#booking-block {
  display: none;
}

/* E-signature */

#signature-pad {
  display: inline-block;
  max-width: 100%;
  margin-top: 10px;
  border: 3px solid #444444;
}
#form-infobox {
  margin-top: 20px;
}
.esig-message {
  margin: 5px 5px;
}

@import 'pages/guardians/bookings/index';
@import 'pages/guardians/course_materials/view';
@import 'pages/guardians/videos/list';
@import 'pages/guardians/videos/show';
@import 'pages/guardians/events/index';
@import 'pages/guardians/events/select';
@import 'pages/guardians/events/show';
@import 'pages/admin/email/templates/index';
@import 'pages/admin/events/sessions/bookings/index';
@import 'pages/admin/video/templates/index';

@import "sections/panel";
@import "sections/printing";
@import "sections/register-new";

@import 'components/admin/inputs';
@import 'components/admin/pagination';
@import 'components/admin/table';
@import "components/alerts";

@import "utilities/admin/content";

i.white-icon{
  font-size:50px !important;
}

select.multiselect{
  width:100%;
  min-height:400px;
}

@import "bootstrap-datetimepicker";
@import "bootstrap-multiselect";

@import "gt-768-sm";
@import "gt-992-md";
@import "gt-1200-lg";
@import "lt-768-xs";

@import "bootstrap-chosen";
@import "chosen";

// Settings

#settings-table {
  margin-top: -1px;

  td.noWrap {
    white-space: nowrap;
  }
}

#admin-settings-edit {
  #json-editor {
    > div > .card > div > div {
      padding: 1px 15px 15px;
      background-color: #EEE;
      margin-bottom: 15px;
    }

    .btn {
      border-color: $input-group-addon-border-color;
    }

    .btn-group {
      .btn {
        border-radius: 5px;
        margin-right: 10px;
      }
    }
  }

  .btn--attachment {
    margin-left: 1rem;
  }
}

// View Appointment Slots
.view-appointment-slots-form {
  margin: 20px 0;

  .form-group {
    margin-right: 10px;

    .btn {
      margin-top: 28px;
    }

    select {
      height: 34px;
    }
  }
}

// View Booked Appointments
.view-booked-appointments-form {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;

  .form-group {
    margin-right: 15px;

    .btn {
      margin-right: 10px;
    }

    select {
      height: 34px;
    }
  }
}

// View Children
#children {
  .medical-info-details {
    padding: 20px;
    margin-top: 20px;
    background-color: #f8f8f8;

    h2 {
      margin-top: 0;
      font-size: 20px;
      color: $default-red;
    }

    table {
      margin-top: 20px;

      tr {
        td:first-of-type {
          width: 75%;
        }
      }
    }

    .medical-info-details-dates {
      margin-top: 20px;
    }
  }
}

#admin-video-create {
  .subtype {
    display: none;
  }
}

#video-show {
  .embed-responsive {
    margin: 30px 0;
  }
}

// Homework system
#admin-homework-view {
  h3 {
    margin-bottom: 10px;
  }

  table {
    width: 100%!important;

    tr td:last-child {
      text-align: left;
    }
  }

  img {
    width: 400px;
  }

  .btn-homework-back {
    margin: 0 0 20px;
  }
}

// Advanced select (used for search dropdowns)
@import "~bootstrap-select/sass/bootstrap-select";

.bootstrap-select {
  .btn {
    border-radius: 0;
    height: 42px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-weight: 400;
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    margin-top: -1px;

    .bs-searchbox {
      padding: 8px;
    }
  }
}

#admin-emailtemplate-index {
  .form-inline {
    display: inline-block;
  }
}

#users-table_wrapper {
  margin-bottom: 20px;

  .dt-buttons {
    margin-bottom: 20px;
  }

  #users-table_filter {
    margin-bottom: 10px;
  }

  #users-table {
    margin-bottom: 10px;
    width: 100%!important;
  }
}

tfoot.filter-row {
  display: table-row-group;

  tr.search-row {
    background-color: lighten($default-grey, 2%);

    th {
      padding: 0!important;

      input {
        width: 100%;
        border: 0;
        padding: 8px 10px;
        border-radius: 0;
        background-color: transparent;

        &::placeholder {
          color: #aaa;
        }

        &:focus {
          outline: none;
        }

        &.highlight {
          background-color: lighten($default-blue, 55%);
        }
      }
    }
  }
}

#forgot-password {

}

.covid-info {
  & > .row {
    margin-top: 30px;

    .alert {
      background-color: $gray-lighter;
      color: $text-color;
      border-color: $gray-light;
      margin-bottom: 0;

      h3 {
        color: $text-color;
      }
    }
  }
}

#admin-courses-parent-index {
  .btn-xs {
    margin-left: 10px;
  }
}

#admin-courses-parent-view table {
  width: 100%!important;

  tr td {
    text-align: left;
  }
}

.tooltip {
  font-size: 1em;
}

.tooltip-inner {
  padding: 10px;
}