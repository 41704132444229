#events-show {
    h2 {
        font-size: 1.3em;
        margin: 1.5em 0 1em;
    }

    h3 {
        margin: 1em 0 0;

        &:first-of-type {
            margin-top: 2em;
        }
    }

    .booked {
        .booking {
            margin-bottom: 1em;
            background-color: $default-grey;
            padding: 1em;

            a {
                margin-top: -0.5em;
            }
        }
    }

    .sessions {
        display: flex;
        gap: 2em;
        margin: 2.5em 0;

        .session {
            border: 1px solid $course-blue;
            padding: 0.75em 1.25em;
            border-radius: 4px;
            margin-top: -1em;
            transition: border-color ease-in-out 150ms;
            font-weight: 600;
            text-align: center;

            &:hover {
                border-color: $default-red;
            }

            &--booked {
                background-color: $main-black;
                border-color: $main-black;
                color: white;

                &:hover {
                    border-color: $main-black;
                }
            }

            &--closed {
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    border-color: #DDD;
                }
            }

            > span {
                display: inline-block;
            }

            &-date {
                font-style: italic;
                margin-bottom: 0.5em;
            }
        }
    }
}