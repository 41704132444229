#admin-emailtemplate-index {
    .top {
        margin-bottom: 20px;
    }

    table {
        margin: 10px 0;
    }

    #root-content {
        margin-bottom: 10px;
    }
}
