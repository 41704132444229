@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }



}
