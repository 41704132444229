.course-item {
    border: 1px solid $default-red;
    margin-bottom: 30px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;

    .course-content {
        padding: 15px;

        .course-content-area {
            min-height: 130px;
        }

        p {
            color: #000;
            font-size: 15px;
            line-height: 23px;
        }

        .course-download-area {
            a.pdf-link {
                float: left;
                padding-top: 8px;
            }
        }

    }

    .course-image {
        height: 175px;
    }

    .course-image {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .course-title {
        border-radius: 4px 4px 0px 0px;
        background-color: $default-red;
        color: #ffffff;
        padding: 10px 15px;
        padding-bottom: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;

        h3 {
            margin: 0;
            padding: 0;
            color: $default-white;
        }
    }


    .course-date {
        text-align: right;
    }
}
