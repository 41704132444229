#admin-video-index {
    table.table {
        font-size: 12px;
    }

    .paging_simple_numbers {
        margin-bottom: 20px;
    }

    .btn-xs {
        min-width: 0;
        padding: 1px 10px;
    }
}

.modal-footer {
    form {
        display: inline-block;
    }
}