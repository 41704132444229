// Let's define our variables here
$default-white: #ffffff;

$secondary-red: #f03d4a;

$header-top-border-height: 4px;

@media screen and (max-width: 767px) {

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  /* Header */

  .header-top-border {
    height: $header-top-border-height;
    margin-left: -15px;
    margin-right: -15px;
  }

  .header-panel .row {
    display: block;
  }

  .header-menu-list {
    padding-bottom: 30px;
}

  .header-logo {
    background-image: url(/img/logo@2x.png);
    float: left;
    height: 60px;
    background-size: 80px;
    background-repeat: no-repeat;
  }

  .header-menu {
    display: none;
    h2{
      padding-left: 18px;
    }
  }

  .header-menu-container {
    background-color: $default-red;

    .header-separator {
      height: 8px;
      right: -15px;
    }

    h1 {
      text-align: left;
      font-size: 24px;
      padding-left: 5px;
    }

    .header-menu-mobile-show {
      top: $header-top-border-height;
    }
  }

  .mobile-menu-button {
    display: block;
    position: absolute;
    top: -48px;
    right: 25px;
    color: #fff;
    cursor: pointer;
    font-size: 25px;
  }

  .header-menu-list {
    padding-top: 10px;
  }

  .header-menu-list li{
    display: block;
    background-color:$default-white;
    border-right:none;
    padding: 20px 0 20px 20px;
    text-align:left;

    a{
      padding:0;
    }
  }

  .header-menu-list li:before {
    content: "";
    position: absolute;
    left: 0!important;
    background: #737373;
    width: 2px;
    transition-property: height;
    transition-duration: 0.3s;
    height: 0;
    transition-timing-function: ease-out;
    z-index: 99999;
  }

  .header-menu-list li:hover:before {
      right: 0px!important;
      height: 100%;
  }

  .header-menu-list li a:hover:before{
    display:none;
  }

  .header-menu-list li a:before{
    display:none;
  }


  .header-menu-right {
    display: none;
  }

  .header-contents {
    .header-title {
      margin-top: 30px;
    }
  }

/* Footer */

  .footer-menu-list-right {
    float: left;
    margin: 5px 0px;
    padding: 5px 0px;
    border-bottom: 1px solid #000;
    border-right: none;
  }

  .footer-menu-list-right li {
    padding: 0 10px;
    float: none;
    border-right: 0;

    &:first-of-type {
      padding-left: 10px;
    }

    a {
      line-height: 2.5em;
    }
  }
  .copyright {
    display: block;
    float: right;
  }
  .home-content {
    padding: 30px 0px;
  }
  .footer-info {

    .separator {
      display: none;
    }
    .signature {
      display: block;
    }
  }

  div.main-content .course-content-title {
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .group-field{
    width:120px;
  }

  #my_children, #successful_bookings, #find_course{
    margin-bottom:20px;
    padding:0;
  }



  /* Registration */
  body#register {
    .login-details {
      .form-group.has-error {
        margin-bottom: 0px;

        .help-block {
          margin-top: 5px;
        }
      }
    }

    .form-group-phone {
        float: none;
    }
  }

  /* Welcome Page */
  .main-content {
    img {
      width: 100%;
    }
  }

  /* Courses */

  .course-image {
    margin-bottom: 15px;
  }
  .course-date {
    text-align: left;
  }
  .course-title h3 {
      margin-bottom: 5px;
  }
  .places {
    float: none;
  }
  .group-item{
      height:auto;
  }

  .home-background img, .icon-container img {
      width: 150px;
  }

  .home-background{
    background-position:50% 50%;
  }

  #contact-1, #contact-2{
    padding:0;
    margin-bottom:10px;
  }

  #bookings{
    .txt-r{
      text-align:left!important;
    }
    .desktop-payment{
      display:none;
    }

    .mobile-payment{
      display:block;
    }

    .booking-item img {
      height: auto;
      max-height:280px;

    }

  }

  #bookings, #waiting-list {
    .btn.btn-ae-white {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  #bookings-confirmed, #register-activation-required{
    .indent{
      padding:0;
    }
  }

  #waiting-list{
    .xs-show{
      display:block;
    }

    .xs-hide{
      display:none;
    }
  }





  .evening-red-box {
    border: 1px dashed red;
    border-radius: 3px;
    margin: 30px;
    &:after, &:before{
      content: '';
      clear: both;
    }
    &>div {
      vertical-align: middle;
    }
    div.redlogo {
      position:relative ;
      width: 100%;
      min-height: 80px;
      padding: 0px;
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        background-image: url(/img/redimg.jpg);
        background-size: contain;
        background-color: #ee222a;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px 0px 0px 4px;
        width: 100%;
        height: 100%;
      }
    }
    div.evening-text{
      width: calc(100%);
      padding-top: 10px;
      padding-bottom: 10px;
      display: inline-block;
      height: auto;
      p {
        padding: 5px 20px;
        font-size: 16px;
        margin: 0px;
        line-height: normal;
        display: block;
        vertical-align: middle;
      }
    }
  }

  .select-teacher {
    > div.select select{
      min-width: auto;
      width: 100%;
      float: none;
    }
  }
  .time-slot > div.radio{
    display: block;

    label, label p{
      flex: none;
      width: 100%;
      display: block;
    }
  }
  .submit-area input.submit{
    width: 100%;
    text-align:center;
  }
  .menu {
   margin-bottom: -1px;
  }

  #medical-info {
    padding: 15px;

    .current-date {
      margin-top: 20px;
    }

  }

}


