.homework-item {
    display: flex;
    border-radius: 5px;
    margin-bottom: 30px;
    overflow: hidden;

    &-image {
        flex: 1;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &-info {
        flex: 4;
        border-radius: 0 5px 5px 0;
        border: 1px solid $default-red;
        border-left: 0;

        h2 {
            background-color: $default-red;
            margin-top: 0;
            color: white;
            padding: 15px 25px;
            font-weight: 600;
        }

        &-details {
            margin-left: 25px;
            margin-top: 20px;

            .btn {
                margin: 10px 20px 20px 0;
            }
        }
    }
}
