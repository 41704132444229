.videos-list {
    h2 {
        font-size: $font-size-h3;
    }

    .video-list-title {
        border-bottom: 1px solid #EEE;
        padding-bottom: 10px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;

        &:after {
            content: "\E080";
            display: block;
            width: 20px;
            height: 100%;
            position: absolute;
            left: 2px;
            top: -5px;
            color: $default-red;
            font-size: 20px;
            font-family: "Glyphicons Halflings";
            transition: 100ms linear transform;
        }

        &.visible {
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    ul.video-list {
        display: none;
        background-color: $default-grey;
        padding: 10px 0 1px;

        &.visible {
            display: block;
        }

        li {
            margin-bottom: 10px;
            margin-left: 20px;
            a {
                color: $default-red;

                span.glyphicon {
                    margin-right: 5px;
                }
            }
        }
    }
}
