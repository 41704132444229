.alert {
    font-weight: 400;
    border: 0;
    border-left-style: solid;
    border-left-width: 3px;
    font-size: 16px;

    &--parentsEvening {
        margin-bottom: 45px;
    }

    &--marginTop {
        margin-top: 15px;
    }

    &--marginBottom {
        margin-bottom: 15px;
    }

    &--sm {
        font-size: 14px;
        padding: 12px;
    }

    &--bold {
        font-weight: 700;
    }

    &-danger {
        color: darken($brand-danger, 35%);
    }

    h3 {
        margin: 0 0 15px;
        font-size: 20px;
        font-weight: 600;
        color: inherit;
    }

    p {
        margin: 0 0 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ul {
        margin-bottom: 10px;
    }

    .btn {
        padding: 10px;
        margin-top: 5px;
    }
}
